.page-content {
  width: 100vw;
}

.page-height--hover {
  height: calc(100vh - 56px);
}

.h-page-nocontainer {
  height: calc(100% - 74px);
}

.h-page-container {
  height: calc(100% - 108px);
}

.bg-page {
  background-image: url(../assets/png/background.png);
  background-color: #eef1f9;
}
