@import url('../src/styles/_global.scss');
@import url('../src/styles/_pages.scss');
@tailwind base;
@tailwind components;
@tailwind utilities;

// Components
@layer components {
  .panel-container {
    @apply bg-white rounded-md shadow-xl;
  }

  .panel-section {
    @apply bg-white rounded-md border;
  }
}

* {
  font-family: 'Inter';
  font-weight: 400;
}

*::-webkit-scrollbar {
  background-color: #d1d1d1;
  border-radius: 20px;
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #322f77;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  width: 5px;
  height: 5px;
}

html {
  overflow-x: hidden;
  background-color: var(--background-color);
}

textarea {
  outline-color: #d1d1d1b5;
}

textarea[disabled] {
  background: #D1D5DB !important;
}

@font-face {
  font-family: 'Inter';
  src: local('InterReular'),
    url(./assets/font/InterRegular.ttf) format('opentype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: local('InterMedium'),
    url(./assets/font/InterMedium.ttf) format('opentype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: local('InterBold'), url(./assets/font/InterBold.ttf) format('opentype');
  font-style: normal;
  font-weight: 700;
}