* {
  --primary-color: #312e81;
  --secondary-color: #82829c;
  --third-color: #f8b100;
  --background-color: #eef1f9;
  --background-filter: #f5f5f5;
  --black-color: #000000;
  --gray-color: #e4e4e4;
  --border-color: #322f87;
  --border-gray-light: #bdbdbd;
}

#modal {
  z-index: 10;
}

.h-95p {
  height: 95%;
}

.rotate-90-text {
  rotate: -90deg;
  translate: -3px 0;
}

kbd {
  background-color: #e7e5e4;
  width: 0.875rem;
  height: 0.625rem;
  padding: 3px;
  font-family: 'Inter' !important;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 10px;
  margin: 0px 3px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.86);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.p-0i {
  padding: 0 !important;
}

.m-0i {
  margin: 0 !important;
}

.h-1\/2-screen {
  max-height: calc(100% - 108px);
  min-height: 55vh;
}

.z-100 {
  z-index: 100111;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}